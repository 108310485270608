.App {
  text-align: center;
  min-height: 100vh;
  min-width: 400px;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  /*.App-logo {
    animation: App-logo-spin infinite 20s linear;
  }*/
}

.App-header {
  background-color: #ffe4c4;
  /*background: linear-gradient(-45deg, rgba(253,85,48,1) 10%, rgba(252,176,69,1) 100%);*/
  min-height: 20vh;
  border-radius: 0 0 0% 90%;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header-section-container{
  background-color:#ffe4c4;
}

.button-1{
  box-shadow: rgb(20 20 20 / 50%) 1px 1px 2px;
    font-weight: bold;
    background: rgb(255, 255, 255);
    padding: 5px 25px;
    font-size: 13px;
    color: rgb(255, 153, 0);
    border-radius: 20px;
    text-decoration: none;
  /*background: linear-gradient(-45deg, rgba(253,85,48,1) 10%, rgba(252,176,69,1) 100%);
  color:#FFFFFF;*/
}

.button-1 :hover{
  background-color: linear-gradient(-45deg, rgb(250, 223, 154) 10%, rgba(252,176,69,1) 100%);;
}

.section-container{
  background-color:#ffe4c4;
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #444444;
  padding:20px;
  position: relative;
  flex-grow: 1;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
